@font-face {
  font-family: 'ES Allianz';
  src: url('/fonts/ESAllianz-Book.woff2') format('woff2'),
    url('/fonts/ESAllianz-Book.woff') format('woff');
}

@font-face {
  font-family: 'ES Allianz';
  src: url('/fonts/ESAllianz-Extrabold.woff2') format('woff2'),
    url('/fonts/ESAllianz-Extrabold.woff') format('woff');
  font-weight: 700;
}

@font-face {
  font-family: 'ES Allianz Extra Bold';
  src: url('/fonts/ESAllianz-Extrabold.woff2') format('woff2'),
    url('/fonts/ESAllianz-Extrabold.woff') format('woff');
}

@font-face {
  font-family: 'Founders Grotesk Mono';
  src: url('/fonts/founders-grotesk-mono-regular.woff2') format('woff2');
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

:root {
  --app-height: 100%;
}

.app-height {
  height: var(--app-height);
}

.min-h-app-height {
  min-height: var(--app-height);
}

@tailwind base;
@tailwind components;
@tailwind utilities;
